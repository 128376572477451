<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '30vw' }"
      :header="'Colocar Instalação como Pendente'"
      :modal="true"
      :closable="false"
    >
      <form
        name="SetPendingForm"
        class="p-col-12"
        @submit.prevent="setPending"
        style="min-height: 15vh"
      >
        <div class="p-fluid p-formgrid p-grid" style="min-height: 50vh">
          <div class="p-field p-col-12 p-md-12">
            <p><b>Instalação {{ installationId }} | {{ installationName }}</b></p>
            <span class="p-float-label">
              <Dropdown
                name="reason"
                :options="listReason"
                :filter="true"
                v-model="reason"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-invalid': errors.has('reason') },
                  'form-control',
                ]"
              >
              </Dropdown>
              <label for="inputtext">Razão</label>
            </span>
            <small
              v-if="errors.has('reason')"
              class="p-error"
              role="alert"
            >
              Razão é obrigatório
            </small>
            
            <span class="p-float-label p-mt-5">
              <Textarea
                name="comment"
                :autoResize="true"
                v-model="comment"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-error': errors.has('comment') },
                  'p-inputtextarea',
                  'p-inputtext',
                  'p-component'
                ]"
                rows="5"
              />
              <label for="comment">Comentário</label>
            </span>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="setPending"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import installationService from "../../services/installations.service";
export default {
  name: "InstallationSetPending",
  props: ["showToast", "show", "installationId", "installationName"],
  watch: {
    installationId() {
      this.reason = null;
      this.comment = null;
    },
  },
  data() {
    return {
      listReason: [],
      reason:null,
      comment:null,
    };
  },
  created() {
    this.listReason = [
      "Cliente",
      "Parceiro",
      "Wifi4media",
      "OnBoarding",
      "Crm"
    ];
  },
  methods: {
    cancel() {
      this.$validator.pause();
      this.$validator.reset();
      this.reason = null;
      this.comment = null;
      return this.$emit("cancel");
    },
    setPending() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          return;
        }
        this.$validator.pause();
        this.$validator.reset();

        let formData = {reason:this.reason};
        if (this.comment != null && this.comment != '') {
          formData.comment = this.comment;
        }
        return installationService
          .setPending(this.installationId, formData)
          .then((response) => {
            if (!response) {
              if (this.showToast) {
                this.$toast.add({
                  severity: "error",
                  summary: "Erro ao alterar o estado Instalação para Pendente",
                  detail: "",
                  life: 3000,
                });
              }
              return this.$emit("setPendingInstallation", {
                status: "error",
                currenStatus: null,
              });
            }

            if (this.showToast) {
              this.$toast.add({
                severity: "success",
                summary: `Estado Instalação alterado para Pendente`,
                detail: ``,
                life: 3000,
              });
            }
            return this.$emit("setPendingInstallation", {
              status: "ok",
              currenStatus: response.status,
            });
          });
      });
    },
  },
};
</script>

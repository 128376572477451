<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '30vw' }"
      :header="'Adicionar Relatório e Data de Relatório'"
      :modal="true"
      :closable="false"
    >
      <form
        name="setReportDateForm"
        class="p-col-12"
        @submit.prevent="setReportDate"
        style="min-height: 15vh"
      >
        <div class="p-field p-col-12 p-md-12">
          <p>
            <b>Instalação {{ installationId }} | {{ installationName }}</b>
          </p>
        </div>
        <div class="p-fluid p-formgrid p-grid" style="min-height: 50vh">
          <div class="p-field p-col-12 p-md-12 p-mt-2">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-calendar"></i>
              </span>
              <span class="p-float-label">
                <v-date-picker
                  name="date"
                  v-model="date"
                  :max-date="new Date()"
                  v-validate="'required'"
                  :masks="{
                    input: 'YYYY-MM-DD',
                  }"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <span class="p-float-label">
                      <input
                        v-bind:class="[
                          { 'p-error': errors.has('date') },
                          'form-control',
                          'p-inputtext',
                          'p-component',
                          'p-filled',
                        ]"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                      <label for="inputtext">Data do Relatorío</label>
                    </span>
                  </template>
                </v-date-picker>
              </span>
            </div>
            <small v-if="errors.has('date')" class="p-error" role="alert">
              Data do relatório é obrigatório
            </small>

            <span class="p-float-label p-mt-5">
              <InputText
                :name="'reportLink'"
                type="text"
                v-model="reportLink"
                v-validate="'required'"
                v-bind:class="[
                  {
                    'p-invalid': errors.has('reportLink'),
                  },
                  'form-control',
                ]"
              />
              <label for="reportLink">Link para o relatório</label>
            </span>
            <small v-if="errors.has('reportLink')" class="p-error" role="alert">
              O Link para o relatório é obrigatório
            </small>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="setReportDate"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import installationService from "../../services/installations.service";
import { getOnlyDate } from "../../helpers/helpers";
export default {
  name: "InstallationSetReportDate",
  props: ["showToast", "show", "installationId", "installationName"],
  watch: {
    installationId() {
      this.date = null;
      this.reportLink = null;
    },
  },
  data() {
    return {
      date: null,
      reportLink: null,
    };
  },
  methods: {
    cancel() {
      this.date = null;
      this.reportLink = null;
      return this.$emit("cancel");
    },
    setReportDate() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          return;
        }
        let bodyParams = {
          reportDate: getOnlyDate(this.date),
          reportUrl: this.reportLink,
        };
        this.$validator.pause();
        this.$validator.reset();
        return installationService
          .setReportDate(this.installationId, bodyParams)
          .then((response) => {
            if (!response) {
              if (this.showToast) {
                this.$toast.add({
                  severity: "error",
                  summary: "Erro ao inserir data do relatório",
                  detail: "",
                  life: 3000,
                });
              }
              return this.$emit("addedReportDate", {
                status: "error",
                currenStatus: null,
              });
            }

            if (this.showToast) {
              this.$toast.add({
                severity: "success",
                summary: `Data do relatório adicionada com sucesso`,
                detail: ``,
                life: 3000,
              });
            }
            return this.$emit("addedReportDate", {
              status: "ok",
              currenStatus: response.status,
            });
          });
      });
    },
  },
};
</script>

<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '30vw' }"
      :header="'Alterar Vendedor da Instalação'"
      :modal="true"
      :closable="false"
    >
      <form
        name="InstallationChangeSeller"
        class="p-col-12"
        @submit.prevent="saveNewSeller"
        style="min-height: 15vh"
      >
        <div class="p-fluid p-formgrid p-grid" style="min-height: 50vh">
          <div class="p-field p-col-12 p-md-12 p-mt-4">
            <span class="p-float-label">
              <Dropdown
                name="sellerUser"
                :options="listAllSellers"
                :filter="true"
                :optionLabel="'name'"
                :optionValue="'id'"
                :dataKey="'id'"
                v-model="current"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-invalid': errors.has('sellerUser') },
                  'form-control',
                ]"
              >
              </Dropdown>
              <label for="inputtext">Selecionar Vendedor</label>
            </span>
            <small
              v-if="errors.has('sellerUser')"
              class="p-error"
              role="alert"
            >
              Vendedor é obrigatório
            </small>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="saveNewSeller"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import installationService from "../../services/installations.service";
import prospectService from "../../services/prospect.service";
export default {
  name: "InstallationChangeSeller",
  props: ["showToast", "show", "currentSeller", "installationId"],
  watch: {
    currentSeller() {
      this.$validator.pause();
      this.$validator.reset();
      this.current = this.findSeller(this.currentSeller);
    },
  },
  data() {
    return {
      current: null,
      listAllSellers: [],
    };
  },
  async created() {
    await this.getActiveSellers();
    this.current = this.findSeller(this.currentSeller)
  },
  methods: {
    getActiveSellers() {
      return prospectService
        .getSellers()
        .then(response => (this.listAllSellers = response));
    },
    cancel() {
      return this.$emit("cancel");
    },
    findSeller(name) {
      if (this.listAllSellers.length == 0 || name==null || name ==0) {
        return 0;
      }
      var sellerId = this.listAllSellers.find(seller => seller.name == name);

      return sellerId.id;
    },
    saveNewSeller() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }
        this.$validator.pause();
        this.$validator.reset();
        return installationService
          .changeSeller(this.installationId, { sellerId: this.current })
          .then((response) => {
            if (!response) {
              if (this.showToast) {
                this.$toast.add({
                  severity: "error",
                  summary: "Erro ao alterar o Vendedor Instalação",
                  detail: "",
                  life: 3000,
                });
              }
              return this.$emit("setInstallationSeller", {
                status: "error",
                currenTechnican: null,
              });
            }

            if (this.showToast) {
              this.$toast.add({
                severity: "success",
                summary: `Vendedor da instalação alterado com sucesso`,
                detail: ``,
                life: 3000,
              });
            }
            return this.$emit("setInstallationSeller", {
              status: "ok",
              currenTechnican: response.technician,
            });
          });
      });
    },
  },
};
</script>

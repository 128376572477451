<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '30vw' }"
      :header="'Alterar Gestor da Instalação'"
      :modal="true"
      :closable="false"
    >
      <form
        name="InstallationChangeManager"
        class="p-col-12"
        @submit.prevent="saveNewManager"
        style="min-height: 15vh"
      >
        <div class="p-fluid p-formgrid p-grid" style="min-height: 50vh">
          <div class="p-field p-col-12 p-md-12 p-mt-4">
            <span class="p-float-label">
              <Dropdown
                name="managerUser"
                :options="listAllUsers"
                :filter="true"
                :optionLabel="'username'"
                :optionValue="'username'"
                v-model="current"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-invalid': errors.has('managerUser') },
                  'form-control',
                ]"
              >
              </Dropdown>
              <label for="inputtext">Selecionar Gestor</label>
            </span>
            <small
              v-if="errors.has('managerUser')"
              class="p-error"
              role="alert"
            >
              Gestor é obrigatório
            </small>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="saveNewManager"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import installationService from "../../services/installations.service";
import usersService from "../../services/user.service";
export default {
  name: "InstallationChangeManager",
  props: ["showToast", "show", "currentManager", "installationId"],
  watch: {
    currentManager() {
      this.$validator.pause();
      this.$validator.reset();
      this.current = this.currentManager;
    },
  },
  data() {
    return {
      current: this.currentManager,
      listAllUsers: [],
    };
  },
  created() {
    this.getActiveUsers();
  },
  methods: {
    getActiveUsers() {
      return usersService
        .activesUsers()
        .then((response) => (this.listAllUsers = response));
    },
    cancel() {
      return this.$emit("cancel");
    },
    saveNewManager() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }
        this.$validator.pause();
        this.$validator.reset();
        return installationService
          .changeManager(this.installationId, { username: this.current })
          .then((response) => {
            if (!response) {
              if (this.showToast) {
                this.$toast.add({
                  severity: "error",
                  summary: "Erro ao alterar o Gestor Instalação",
                  detail: "",
                  life: 3000,
                });
              }
              return this.$emit("setInstallationManager", {
                status: "error",
                currenTechnican: null,
              });
            }

            if (this.showToast) {
              this.$toast.add({
                severity: "success",
                summary: `Gestor da instalação alterado com sucesso`,
                detail: ``,
                life: 3000,
              });
            }
            return this.$emit("setInstallationManager", {
              status: "ok",
              currenTechnican: response.technician,
              office: response.office,
            });
          });
      });
    },
  },
};
</script>

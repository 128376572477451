<template>
  <div class="p-col-12">
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '40vw' }"
      :header="'Marcar Instalação'"
      :modal="true"
      :closable="false"
    >
      <div class="card">
        <form
          name="setMarkedForm"
          class="p-col-12"
          @submit.prevent="setReportDate"
          style="min-height: 20vh"
        >
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-12">
              <p>
                <b>Instalação {{ installationId }} | {{ installationName }}</b>
              </p>
            </div>

            <div class="p-field p-col-12 p-md-12 p-mt-2">
              <span class="p-float-label">
                <Dropdown
                  v-model="pat"
                  name="pat"
                  v-validate="'required'"
                  v-bind:class="[
                    { 'p-error': errors.has('pat') },
                    'form-control',
                  ]"
                  :options="patsList"
                  :optionLabel="getPatsSearchLabel"
                  :optionValue="'id'"
                  :dataKey="'id'"
                  :filter="true"
                  :required="true"
                >
                  <template #option="slotProps">
                    <b>Pat</b> {{ slotProps.option.id }} | <b>Titulo</b>
                    {{ slotProps.option.resume }} | <b>Projeto</b>
                    {{ slotProps.option.name }}
                  </template>
                </Dropdown>
                <label for="pat">Pat</label>
              </span>
              <small v-if="errors.has('pat')" class="p-error" role="alert"
                >Pat é obrigatório</small
              >
            </div>

            <div class="p-field p-col-12 p-md-12 p-mt-2">
              <span class="p-float-label">
                <Dropdown
                  v-model="respTechnical"
                  name="respTechnical"
                  v-validate="'required'"
                  v-bind:class="[
                    { 'p-error': errors.has('respTechnical') },
                    'form-control',
                  ]"
                  :options="technicals"
                  :optionLabel="getUserSearchLabel"
                  :optionValue="'id'"
                  :dataKey="'id'"
                  :filter="true"
                  :required="true"
                >
                  <template #option="slotProps">
                    {{ slotProps.option.username }} ({{
                      slotProps.option.office
                    }})
                  </template>
                </Dropdown>
                <label for="respTechnical">Técnico Responsável</label>
              </span>
              <small
                v-if="errors.has('respTechnical')"
                class="p-error"
                role="alert"
                >Técnico Responsável é obrigatório</small
              >
            </div>
            <div class="p-field p-col-12 p-md-12 p-mt-2">
              <span class="p-float-label">
                <MultiSelect
                  name="moreTechnicals"
                  :options="technicals"
                  :filter="true"
                  :optionLabel="getUserSearchLabel"
                  :optionValue="'id'"
                  :dataKey="'id'"
                  v-model="moreTechnicals"
                  display="chip"
                >
                  <template #option="slotProps">
                    {{ slotProps.option.username }} ({{
                      slotProps.option.office
                    }})
                  </template>
                </MultiSelect>
                <label for="inputtext">Mais Técnicos</label>
              </span>
            </div>

            <div class="p-field p-col-12 p-md-4 p-mt-2">
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                  <i class="pi pi-calendar"></i>
                </span>
                <span class="p-float-label">
                  <v-date-picker
                    name="day"
                    id="day"
                    v-model="day"
                    v-validate="'required'"
                    :min-date="new Date()"
                    :masks="{
                      input: 'YYYY-MM-DD',
                    }"
                  >
                    <template v-slot="{ inputValue, inputEvents }">
                      <span class="p-float-label">
                        <input
                          v-bind:class="[
                            {
                              'p-error': errors.has('day'),
                            },
                            'form-control',
                            'p-inputtext',
                            'p-component',
                            'p-filled',
                          ]"
                          :value="inputValue"
                          v-on="inputEvents"
                        />
                        <label for="day">Dia</label>
                      </span>
                    </template>
                  </v-date-picker>
                </span>
              </div>
              <small v-if="errors.has('day')" class="p-error" role="alert"
                >Dia é obrigatório</small
              >
            </div>

            <div class="p-field p-col-12 p-md-4 p-mt-2">
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                  <i class="pi pi-clock"></i>
                </span>
                <span class="p-float-label">
                  <Calendar
                    id="start"
                    ref="start"
                    v-model="start"
                    v-validate="'required|date_format:H:mm'"
                    v-bind:class="[
                      { 'p-error': errors.has('start') },
                      'form-control',
                    ]"
                    :timeOnly="true"
                    :stepMinute="15"
                    name="start"
                  />
                  <label for="inputtext">Hora de início</label>
                </span>
              </div>
              <small v-if="errors.has('start')" class="p-error" role="alert"
                >Hora de início é obrigatório</small
              >
            </div>

            <div class="p-field p-col-12 p-md-4 p-mt-2">
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                  <i class="pi pi-clock"></i>
                </span>
                <span class="p-float-label">
                  <Calendar
                    id="end"
                    v-model="end"
                    v-validate="'required|date_format:H:mm|after_start'"
                    v-bind:class="[
                      { 'p-error': errors.has('end') },
                      'form-control',
                    ]"
                    :timeOnly="true"
                    :stepMinute="15"
                    name="end"
                  />
                  <label for="inputtext">Hora de fim</label>
                </span>
              </div>
              <small v-if="errors.has('end')" class="p-error" role="alert"
                >Hora de fim tem que ser posterior à de início</small
              >
            </div>
            <div class="p-field p-col-12 p-md-12 p-mt-2">
              <span class="p-float-label">
                <Textarea
                  id="note"
                  name="note"
                  :autoResize="true"
                  v-model="note"
                  v-bind:class="[
                    'p-inputtextarea',
                    'p-inputtext',
                    'p-component',
                  ]"
                  rows="5"
                />
                <label for="inputtext">Nota</label>
              </span>
            </div>
          </div>
        </form>
      </div>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="setReportDate"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import installationService from "../../services/installations.service";
import { getOnlyDate, getOnlyTime } from "../../helpers/helpers";
import VeeValidate from "vee-validate";
export default {
  name: "InstallationSetMarked",
  props: ["showToast", "show", "installationId", "installationName"],
  watch: {
    installationId() {
      this.reset();
      this.getImplementationPats();
    },
  },
  data() {
    return {
      technicals: [],
      moreTechnicals: [],
      patsList: [],
      respTechnical: null,
      day: null,
      start: null,
      end: null,
      note: "",
      pat: null,
    };
  },
  beforeMount() {
    Object.keys(this.$store.state.auth.user.supportUsers).forEach(office => {
      this.technicals = this.technicals.concat(this.$store.state.auth.user.supportUsers[office])
    })
  },
  async created() {
    await this.getImplementationPats();
    VeeValidate.Validator.extend("after_start", {
      validate: () =>
        new Promise((resolve) => {
          let day = new Date();
          if (!(this.day instanceof Date)) {
            day = new Date(this.day);
          } else {
            day = this.day;
          }

          if (!(this.start instanceof Date)) {
            let newStart = new Date();
            newStart.setSeconds(0);
            newStart.setHours(this.start.split(":")[0]);
            newStart.setMinutes(this.start.split(":")[1]);
            this.start = newStart;
          }
          if (!(this.end instanceof Date)) {
            let newEnd = new Date();
            newEnd.setSeconds(0);
            newEnd.setHours(this.end.split(":")[0]);
            newEnd.setMinutes(this.end.split(":")[1]);
            this.end = newEnd;
          }

          this.start.setFullYear(
            day.getFullYear(),
            day.getMonth(),
            day.getDate()
          );
          this.end.setFullYear(
            day.getFullYear(),
            day.getMonth(),
            day.getDate()
          );
          resolve({
            valid: this.end > this.start,
          });
        }),
    });
  },
  methods: {
    reset() {
      this.moreTechnicals = [];
      this.patsList = [];
      this.respTechnical = null;
      this.day = null;
      this.start = null;
      this.end = null;
      this.note = "";
      this.pat = null;
    },
    getUserSearchLabel(info) {
      return `${info.username} (${info.office})`;
    },
    getPatsSearchLabel(info) {
      return `Pat ${info.id} | ${info.resume}`;
    },
    cancel() {
      this.reset();
      return this.$emit("cancel");
    },
    getImplementationPats() {
      if (this.installationId != undefined && this.installationId != null) {
        installationService
          .getImplementationPats(this.installationId)
          .then((response) => (this.patsList = response));
      }
    },
    setReportDate() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          return;
        }
        let bodyParams = {
          pat: this.pat,
          day: getOnlyDate(this.day),
          start: getOnlyTime(this.start),
          end: getOnlyTime(this.end),
          respTechnical: this.respTechnical,
          note: this.note,
        };
        bodyParams.moreTechnicals = this.moreTechnicals.filter(
          (tec) => tec != this.respTechnical
        );
        this.$validator.pause();
        this.$validator.reset();
        return installationService
          .setMarked(this.installationId, bodyParams)
          .then((response) => {
            if (!response) {
              if (this.showToast) {
                this.$toast.add({
                  severity: "error",
                  summary: "Erro ao marcar instalação",
                  detail: "",
                  life: 3000,
                });
              }
              return this.$emit("marked", {
                status: "error",
                currenStatus: null,
              });
            }

            if (this.showToast) {
              this.$toast.add({
                severity: "success",
                summary: `Instalação marcada com sucesso`,
                detail: ``,
                life: 3000,
              });
            }
            return this.$emit("marked", {
              status: "ok",
              currenStatus: response.status,
            });
          });
      });
    },
  },
};
</script>

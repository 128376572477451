<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '30vw' }"
      :header="'Retirar Instalação de Pendente'"
      :modal="true"
      :closable="false"
    >
      <div class="p-fluid p-formgrid p-grid" style="min-height: 50vh">
        <div class="p-field p-col-12 p-md-12">
          <p>
            <b>Instalação {{ installationId }} | {{ installationName }}</b>
          </p>
          <div class="p-field p-col-12 p-mt-4">
            <span
              class="p-float-label"
              style="top: -0.75rem; left: -1rem; font-size: 12px"
            >
              <label for="confirm">Retirar a Instalação de Pendente?</label>
            </span>
            <InputSwitch
              v-model="confirm"
              :name="'confirm'"
              style="'top: 3px;"
            />
            <small
              v-if="showError"
              class="p-error"
              role="alert"
            >
              Tem de confirmar que pretende retirar o estado pendente
            </small>
          </div>
          <span class="p-float-label p-mt-4">
            <Textarea
              name="comment"
              :autoResize="true"
              v-model="comment"
              v-validate="'required'"
              v-bind:class="[
                { 'p-error': errors.has('comment') },
                'p-inputtextarea',
                'p-inputtext',
                'p-component',
              ]"
              rows="5"
            />
            <label for="comment">Comentário</label>
          </span>
        </div>
      </div>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="removePending"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import installationService from "../../services/installations.service";
export default {
  name: "InstallationRemovePending",
  props: ["showToast", "show", "installationId", "installationName"],
  watch: {
    installationId() {
      this.confirm = false;
      this.showError = false;
      this.comment = null;
    },
  },
  data() {
    return {
      confirm: false,
      showError: false,
      comment: null,
    };
  },
  methods: {
    cancel() {
      this.confirm = false;
      this.showError = false;
      this.comment = null;
      return this.$emit("cancel");
    },
    removePending() {
      if (this.confirm === false) {
        return this.showError = true;
      }
      let formData = {};
      if (this.comment != null && this.comment != "") {
        formData.comment = this.comment;
      }
      return installationService
        .removePending(this.installationId, formData)
        .then((response) => {
          if (!response) {
            if (this.showToast) {
              this.$toast.add({
                severity: "error",
                summary: "Erro ao alterar o estado Instalação para Pendente",
                detail: "",
                life: 3000,
              });
            }
            return this.$emit("removedPendingInstallation", {
              status: "error",
              currenStatus: null,
            });
          }

          if (this.showToast) {
            this.$toast.add({
              severity: "success",
              summary: `Estado Instalação alterado para Pendente`,
              detail: ``,
              life: 3000,
            });
          }
          return this.$emit("removedPendingInstallation", {
            status: "ok",
            currenStatus: response.status,
          });
        });
    },
  },
};
</script>

<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '30vw' }"
      :header="'Preencher Data de Piloto'"
      :modal="true"
      :closable="false"
    >
      <div class="p-fluid p-formgrid p-grid" style="min-height: 50vh">
        <div class="p-field p-col-12 p-md-12">
          <p>
            <b>Instalação {{ installationId }} | {{ installationName }}</b>
          </p>
          <div class="p-d-flex p-jc-center">
            <v-date-picker
              v-model="date"
              :max-date="new Date()"
              :masks="{
                input: 'YYYY-MM-DD',
              }"
            >
            </v-date-picker>
          </div>
        </div>
      </div>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="setDeliveryDate"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import installationService from "../../services/installations.service";
import { getOnlyDate } from "../../helpers/helpers";
export default {
  name: "InstallationSetDeliveryDate",
  props: ["showToast", "show", "installationId", "installationName"],
  watch: {
    installationId() {
      this.date = new Date(this.patDate);
    },
  },
  data() {
    return {
      date: new Date(this.patDate),
    };
  },
  methods: {
    cancel() {
      this.date = new Date(this.patDate);
      return this.$emit("cancel");
    },
    setDeliveryDate() {
      let bodyParams = {
        date: getOnlyDate(this.date),
      };
      return installationService
        .setDeliveryDate(this.installationId, bodyParams)
        .then((response) => {
          if (!response) {
            if (this.showToast) {
              this.$toast.add({
                severity: "error",
                summary: "Erro ao inserir data de entrega",
                detail: "",
                life: 3000,
              });
            }
            return this.$emit("addedDeliveryDate", {
              status: "error",
              currenStatus: null,
            });
          }

          if (this.showToast) {
            this.$toast.add({
              severity: "success",
              summary: `Data de entrega adicionada com sucesso`,
              detail: ``,
              life: 3000,
            });
          }
          return this.$emit("addedDeliveryDate", {
            status: "ok",
            currenStatus: response.status,
          });
        });
    },
  },
};
</script>

<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '30vw' }"
      :header="'Concluir Instalação'"
      :modal="true"
      :closable="false"
    >
      <div class="p-fluid p-formgrid p-grid" style="min-height: 50vh">
        <div class="p-field p-col-12 p-md-12">
          <p>
            <b>Instalação {{ installationId }} | {{ installationName }}</b>
          </p>
          <div class="p-field p-col-12 p-mt-4">
            <span
              class="p-float-label"
              style="top: -0.75rem; left: -1rem; font-size: 12px"
            >
              <label for="confirm">Concluir a Instalação?</label>
            </span>
            <InputSwitch
              v-model="confirm"
              :name="'confirm'"
              style="'top: 3px;"
            />
            <small v-if="showError" class="p-error" role="alert">
              Tem de confirmar que pretende concluir a instalação
            </small>
          </div>
        </div>
      </div>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="setConclude"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import installationService from "../../services/installations.service";
export default {
  name: "InstallationSetConclude",
  props: ["showToast", "show", "installationId", "installationName"],
  watch: {
    installationId() {
      this.confirm = false;
      this.showError = false;
    },
  },
  data() {
    return {
      confirm: false,
      showError: false,
    };
  },
  methods: {
    cancel() {
      this.confirm = false;
      this.showError = false;
      return this.$emit("cancel");
    },
    setConclude() {
      if (this.confirm === false) {
        return (this.showError = true);
      }
      return installationService
        .setConclude(this.installationId)
        .then((response) => {
          if (!response) {
            if (this.showToast) {
              this.$toast.add({
                severity: "error",
                summary: "Erro ao concluir a Instalação",
                detail: "",
                life: 3000,
              });
            }
            return this.$emit("completedInstallation", {
              status: "error",
              currenStatus: null,
            });
          }

          if (this.showToast) {
            this.$toast.add({
              severity: "success",
              summary: `Instalação concluida com sucesso`,
              detail: ``,
              life: 3000,
            });
          }
          return this.$emit("completedInstallation", {
            status: "ok",
            currenStatus: response.status,
          });
        });
    },
  },
};
</script>
